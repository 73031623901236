import React, { Suspense, lazy } from "react";
import { CpGlobalBanner } from "canopy-styleguide!sofe";

const PractitionerContainer = lazy(() =>
  import(
    /* webpackChunkName: "PractitionerContainer" */ "./practitioner/practitioner.container.js"
  )
);

export function LoadPractitionerContainer(props) {
  return (
    <Suspense fallback={null}>
      <CpGlobalBanner />
      <PractitionerContainer {...props} />
    </Suspense>
  );
}
